<div class="social-media-buttons-container">
  <a
    data-testid="share-picture-twitter"
    class="social-media-btn"
    shareButton="twitter"
    [url]="url"
    [title]="title"
    [description]="description"
    [image]="image"
    [tags]="tags"
  >
    <img src="assets/images/social-media-icons/twitter.svg" />
  </a>
  <a
    data-testid="share-picture-facebook"
    class="social-media-btn"
    shareButton="facebook"
    [url]="url"
    [title]="title"
    [description]="description"
    [image]="image"
    [tags]="tags"
  >
    <img src="assets/images/social-media-icons/facebook.svg" />
  </a>
  <a
    data-testid="share-picture-linkedin"
    class="social-media-btn"
    shareButton="linkedin"
    [url]="url"
    [title]="title"
    [description]="description"
    [image]="image"
    [tags]="tags"
  >
    <img src="assets/images/social-media-icons/linkedin.svg" />
  </a>
</div>
