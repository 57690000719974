import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-social-media-share',
  templateUrl: './social-media-share.component.html',
  styleUrls: ['./social-media-share.component.scss'],
  standalone: false,
})
export class SocialMediaShareComponent {
  @Input() public url = '';
  @Input() public title = '';
  @Input() public description = '';
  @Input() public image = '';
  @Input() public tags = '';
}
