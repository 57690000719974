import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-social-media-share-modal',
  templateUrl: './social-media-share-modal.component.html',
  styleUrls: ['./social-media-share-modal.component.scss'],
  standalone: false,
})
export class SocialMediaShareModalComponent {
  public url: string;
  public title: string;
  public description: string;
  public image: string;
  public tags: string;

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: SocialMediaShareModalData,
    public dialogRef: MatDialogRef<SocialMediaShareModalComponent>,
  ) {
    this.url = data.url;
    this.title = data.title ?? '';
    this.description = data.description ?? '';
    this.image = data.image ?? '';
    this.tags = data.tags ?? '';
  }
}

export interface SocialMediaShareModalData {
  url: string;
  title?: string;
  description?: string;
  image?: string;
  tags?: string;
}
