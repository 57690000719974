<div class="media-share-modal-container">
  <div class="close-button-container">
    <mat-icon class="close-button mat-icon" aria-hidden="true" data-mat-icon-type="font" (click)="dialogRef.close()"
      >close</mat-icon
    >
  </div>

  <div class="media-share-modal-title-container">
    <h2 class="media-share-modal-title">Share via</h2>
  </div>
  <app-social-media-share
    [url]="url"
    [title]="title"
    [description]="description"
    [image]="image"
    [tags]="tags"
  ></app-social-media-share>
</div>
