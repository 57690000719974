<!-- <h2 mat-dialog-title style="text-align: center">Image Cropper</h2> -->
<div tabindex="0" aria-label="Image Cropper" class="cropper-container">
  <image-cropper
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    [aspectRatio]="aspectRatio"
    [resizeToWidth]="resizeToWidth"
    format="png"
    (imageCropped)="imageCropped($event)"
    (loadImageFailed)="loadImageFailed()"
  ></image-cropper>
</div>
@if (errMessage) {
  <div tabindex="0">{{ errMessage }}</div>
}
