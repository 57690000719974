<div class="snackbar {{ mode }} {{ type ? 'type-' + type : '' }}">
  <div class="color-bar"></div>
  @if (icon && isMatIcon) {
    <span class="snackbar-icon">
      <mat-icon [style]="iconSizeStyle">
        {{ icon }}
      </mat-icon>
    </span>
  } @else if (icon && !isMatIcon) {
    <span class="snackbar-icon">
      <mat-icon svgIcon="{{ icon }}"></mat-icon>
    </span>
  }
  <div matSnackBarLabel>
    @if (customTitle) {
      <span class="snackbar-title">{{ customTitle | titlecase }}</span>
    } @else {
      <span class="snackbar-title">{{ type | titlecase }}</span>
    }
    <span class="snackbar-message" [innerHTML]="message"> </span>
  </div>
  <span class="snackbar-actions" matSnackBarActions>
    <button
      type="button"
      title="close"
      class="snackbar-close"
      mat-button
      matSnackBarAction
      (click)="snackBarRef.dismissWithAction()"
    >
      <mat-icon svgIcon="CANCEL" class="snackbar-close-icon"></mat-icon>
    </button>
  </span>
</div>
