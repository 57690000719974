import { Pipe, PipeTransform } from '@angular/core';
import { AsYouType } from 'libphonenumber-js';
import { CountryCode } from 'libphonenumber-js/min';

@Pipe({
  name: 'phoneFormat',
  standalone: false,
})
export class PhoneFormatPipe implements PipeTransform {
  public transform(phoneValue: string, country: string): string {
    try {
      return new AsYouType(country as CountryCode).input(phoneValue);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      return phoneValue;
    }
  }
}
