import { Component, Inject } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';

import { ModalEventService } from '../modals/modal-event.service';
import { CONTAINER_DATA } from '../modals/modal.service';

export interface SharedCropperComponentData {
  resizeToWidth?: number;
  aspectRatio?: number;
  data: unknown;
}

@Component({
  templateUrl: 'cropper.component.html',
  styleUrls: ['./cropper.component.scss'],
  standalone: false,
})
export class SharedCropperComponent {
  public aspectRatio = 1;
  public resizeToWidth = 600;
  public imageChangedEvent: unknown;
  public errMessage = '';

  public constructor(
    private eventService: ModalEventService,
    @Inject(CONTAINER_DATA) public data: SharedCropperComponentData,
  ) {
    this.imageChangedEvent = data.data;
    if (data.resizeToWidth) {
      this.resizeToWidth = data.resizeToWidth;
    }
    if (data.aspectRatio) {
      this.aspectRatio = data.aspectRatio;
    }
  }

  public imageCropped(event: ImageCroppedEvent): void {
    this.eventService.peek().next(event);
  }

  public loadImageFailed(): void {
    this.errMessage = 'File type not supported.';
  }
}
